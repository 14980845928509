import axiosObject from "../configs/axios.config";
import { HttpMethods } from "../enums/http-methods.enum";
import { AxiosHeaders } from "axios";

export const baseURL = process.env.REACT_APP_BACKEND_CORE_URL;

export type MakeRequest = {
  url: string;
  method: HttpMethods;
  data?: Object;
  params?: Object;
  headers?: Object;
};

export const makeRequest = async (req: MakeRequest): Promise<any> => {
  const { url, method, data, params, headers } = req;
  try {
    const response = await axiosObject({
      url,
      method,
      data,
      params,
      headers: headers as AxiosHeaders, // Add type annotation here
      baseURL,
    });
    return response.data;
  } catch (error) {
    console.error("Request failed:", error);
    throw error;
  }
};
